import React, { useState, useEffect } from 'react';
import Images from '../Images';
import { Link } from 'react-router-dom';
import user from '../../mobx/user';
import { Observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { get, post, path } from '../../api';
import EditProfile from '../Modal/EditProfile';

const NavbarHead = ({
	userRole,
	menuOpen,
	menuNav,
	history,
	notiDetail,
	openDrop,
	dropNoti,
	amount_noti,
	check_noti
}) => {
	const [profile, setProfile] = useState(null);
	const [openModalU, setopenModalU] = useState(false);
	// load profile data
	useEffect(async () => {
		try {
			const get_profile = await get('/auth/profile');
			setProfile({ ...get_profile });
		} catch (error) {
			console.error(error.message);
		}
	}, []);

	function onOpenModal() {
		setopenModalU(true);
	}
	function onCloseModal() {
		setopenModalU(false);
	}

	const onLogout = async () => {
		await post('/auth/logout', { noti_token: user.token });
		history.push('/login');
		user.removeUser();
	};

	const [Menuslide, setMenuslide] = useState(false);
	function openMenuSlide() {
		setMenuslide(!Menuslide);
	}
	let pathname = window.location.pathname;
	let title =
		pathname === '/'
			? 'แจ้งเรื่องร้องเรียน'
			: pathname === '/tracking'
				? 'ติดตามเรื่อง'
				: pathname === '/hotline'
					? 'สายด่วน'
					: pathname === '/question'
						? 'แนวทางแก้ทุกข์'
						: pathname === '/rate' ? 'ประเมินความพึงพอใจ' : pathname === '/news' ? 'ข่าวจริงประเทศไทย' : null;
	return (
		<Observer>
			{() => (
				<React.Fragment>
					<div className="nav-head">
						{user.role.toLocaleLowerCase() !== 'user' ? (
							<React.Fragment>
								<div className="nav-head-left">
									<img onClick={menuNav} src={Images.Asset40} alt="" style={{ cursor: 'pointer' }} />
								</div>
							</React.Fragment>
						) : (
								<React.Fragment>
									<div className="nav-head-left">
										<div className="nav-head-left-img">
											{/* ================= profile ================*/}
											<img src={profile ? path + profile.path : Images.Asset30} alt="" />
										</div>
										<p className="h5">
											{profile && profile.fname} {profile && profile.lname}
										</p>
										<div className="ml-2">
											<i
												style={{ cursor: 'pointer' }}
												onClick={onOpenModal}
												className="fas fa-edit"
											/>
										</div>
										<EditProfile onCloseModal={onCloseModal} onOpenModalU={openModalU} />
									</div>
								</React.Fragment>
							)}
						<div className="nav-head-cen diofguy">
							<img src={Images.Asset42} alt="" />
						</div>
						<div className="rrtcvcvb">{title}</div>
						<div className="nav-head-right">
							<div className="nav-head-right-radius" onClick={dropNoti}>
								{amount_noti.length !== 0 ? (
									<div className="noti-alert">
										<p>{check_noti === true && '!'}</p>
									</div>
								) : null}
								<img src={Images.Asset6} alt="" />
							</div>
							{openDrop ? (
								<React.Fragment>
									<div className="dropdownNoti styleScroll">
										{notiDetail.length === 0 ? (
											<div className="layerNotNoti">
												<p>ไม่มีการตอบกลับ</p>
											</div>
										) : (
												notiDetail.map((el, i) => {
													return (
														<div
															className={`layerNoti ${el.read === 0 ? 'even' : ''}`}
															key={i}
															onClick={async () => {
																await post('/form/update_noti', {
																	id: el.id,
																	key: el.key
																});
																history.push('/lookcomplaint/' + Number(el.form_id));
																window.location.reload();
																// dropNoti();
															}}
														>
															<p>รหัสเรื่องร้องเรียน {el.DKK}</p>
															<p>
																{el.key}
																{el.status && ' : ' + el.status}
															</p>
															<span>{el.date}</span>
														</div>
													);
												})
											)}
									</div>
									<div className="bgDrop" onClick={dropNoti} />
								</React.Fragment>
							) : null}
							<div className="nav-head-right-out" onClick={onLogout}>
								<img src={Images.Asset7} alt="" />
								<p>ออกจากระบบ</p>
							</div>
						</div>
					</div>
					{/* --------------------------------------- */}
					<div className={`${Menuslide ?'bg-closemenu':''}`}  onClick={() => openMenuSlide()}/>
					<div className={`nav-left ${Menuslide ? 'openmenu' : 'closemenu'} zxcvbn`}>
						<i
							className={`fas ${Menuslide ? 'fa-times  text-danger' : 'fa-bars'} mw768-menu`}
							onClick={() => openMenuSlide()}
						/>
						<div>
							<div className="nav-left-label">
								<div className="nav-left-top -admin">
									<div className="admin-logo-head">
										<img src={Images.logo} alt="" />
										<div className="-right">
											<h2>ศูนย์ดำรงธรรม</h2>
											<h5>จังหวัดขอนแก่น</h5>
										</div>
									</div>
									<div className="setFxCen">
										<div className="-cycle">
											<img alt="" src="https://damrongdhamakk.go.th/api/v1/static/user_profile.png" />
										</div>
									</div>
									<p>{/* {profile && profile.fname} {profile && profile.lname} */}</p>
									{/* <label>{profile && profile.central_name}</label> */}
									{/* <button onClick={() => onOpenModal()}>แก้ไขข้อมูล</button> */}
								</div>
								<ul>
									<li>
										<Link to="/">แจ้งเรื่องร้องเรียน</Link>
									</li>
									<li>
										<Link to="/tracking">ติดตามเรื่องและสถานะเรื่อง</Link>
									</li>
									<li>
										<Link to="/hotline">สายด่วน</Link>
									</li>
									<li>
										<Link to="/question">คำถามที่พบบ่อย/FAQ</Link>
									</li>
									<li>
										<Link to="/rate">ประเมินความพึงพอใจ</Link>
									</li>
									<li>
										<Link to="/news">ข่าวจริงประเทศไทย</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</React.Fragment>
			)}
		</Observer>
	);
};

const _NavbarHead = withRouter(NavbarHead);

export default _NavbarHead;
