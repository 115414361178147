import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import NavbarTop from './Components/Navbar/NavbarTop';
import NavbarHead from './Components/Navbar/NavbarHead';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Asset/css/style.css';
import './Asset/css/adminstyle.css';
import './Asset/css/reportstyle.css';
import './Asset/css/mainstyle.css';
import './Asset/css/tablestyle.css';
import './Asset/css/radiorate.css';
import './Asset/css/btnStyle.css';
import './Asset/scss/accordionStyle.css';
import './Asset/css/componentStyle.css';
import NavbarFooter from './Components/Navbar/NavbarFooter';
import { observer } from 'mobx-react';
import User from './mobx/user';
import { post } from './api';
import moment from 'moment';

@observer
class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			role: false,
			menuOpen: true,
			notiDetail: [],
			openDrop: false,
			amount_noti: [],
			check_noti: true
		};
	}
	// ------------------------------------------------------------------------------------------------------------------
	componentWillMount = async () => {
		let th = require('moment/locale/th');
		moment.updateLocale('th', th);
		if (!User.role) {
			this.props.history.push('/login');
		} else {
			this.Noti_User();
		}
	};
	componentDidMount() {
		this.interval = setInterval(async () => {
			this.Noti_User();
		}, 20000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}
	Noti_User = async () => {
		let res = await post('/form/get_form_noti');
		this.setState({
			notiDetail: res.change
				.concat(res.tracking)
				.concat(res.tranfer)
				.concat(res.addadmin)
				.sort((a, b) => moment(b.insert_at) - moment(a.insert_at))
				.map((e) => ({
					...e,
					date: moment(e.insert_at).add(543, 'year').format('LLL')
				})),
			amount_noti: res.change
				.concat(res.tracking)
				.concat(res.tranfer)
				.concat(res.addadmin)
				.filter((e) => e.read === 0),
			check_noti: res.change
				.concat(res.tracking)
				.concat(res.tranfer)
				.concat(res.addadmin)
				.some((e) => e.read === 0)
		});
	};
	// ------------------------------------------------------------------------------------------------------------------
	menuNav = () => {
		this.setState({ menuOpen: !this.state.menuOpen });
	};
	dropNoti = () => {
		this.setState({ openDrop: !this.state.openDrop });
	};
	// ------------------------------------------------------------------------------------------------------------------

	render() {
		let { notiDetail, openDrop, amount_noti, check_noti } = this.state;
		return (
			<React.Fragment>
				{/* <div className={`App ${Message.showChat ? ' slide' : ''}`} > */}
				<div className="App">
					<div className="setCenter">
						<header className="NavHeader">
							<NavbarHead
								notiDetail={notiDetail}
								userRole={this.state.role}
								menuNav={this.menuNav}
								menuOpen={this.state.menuOpen}
								dropNoti={this.dropNoti}
								openDrop={openDrop}
								amount_noti={amount_noti}
								check_noti={check_noti}
							/>
							<NavbarTop userRole={this.state.role} />
						</header>
						{/* <NavbarLeft /> */}
						{this.props.children}
					</div>

					<NavbarFooter />
				</div>
				{/* <Chat className={ Message.showChat ? 'slide' : 'slide-close' }/> */}
			</React.Fragment>
		);
	}
}

export default withRouter(App);
