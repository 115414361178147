import React from 'react';
import LabelInput from '../LabelInput';
import {
	ContanierRadio,
	Label,
	LabelLight,
	ContainerRow,
	InputText,
	ContainerForm,
	InputTextarea,
	ContainerRadioRow
} from '../../Asset/styled';
import { Row, Col } from 'reactstrap';
import InputMask from 'react-input-mask';

export function InputForm({ item, errors, onChangeText, active = true, value, maxLength }) {
	let { ref, title, placeholder, validation, length, mark, reg } = item;
	return (
		<LabelInput key={ref}>
			<Label>{title}</Label>
			<InputText
				type={validation ? validation.type : 'text'}
				disabled={!active}
				placeholder={placeholder}
				name={ref}
				onChange={(e) => onChangeText(e, reg)}
				className="form-control "
				value={value ? value[ref] : null}
				maxLength={length}
				bc={errors && errors[ref] ? '#ff3300' : ''}
			/>
			<small class={`form-text pl-1 ${errors && errors[ref] ? 'error_text' : ''}`}>{mark}</small>
		</LabelInput>
	);
}

export function RadioForm({ item, onChangeRadio, checked }) {
	let { ref, title, data, xs, sm, md, lg } = item;
	return (
		<ContainerForm>
			<ContainerRow key={ref}>
				<Label className="cfg09ds">
					{title}
				</Label>
				<Row style={{width:'calc(100% - 60px)'}}>
					{data.map((el, i) => {
						let { label, value } = el;
						return (
							<Col xs={xs} sm={sm} md={md} lg={lg} key={i} className="d-flex justify-content-start">
								<input
									type={'radio'}
									value={value}
									name={ref}
									id={value}
									onChange={onChangeRadio}
									className="-comp-form-input"
									checked={checked == value}
								/>
								<LabelLight for={value}>{label}</LabelLight>
							</Col>
						);
					})}
				</Row>
				<small class={`form-text pl-1}`} />
			</ContainerRow>
		</ContainerForm>
	);
}

export function RadioRow({ item, onChangeRadio, checked }) {
	let { ref, data } = item;
	return (
		<div key={ref}>
			{data.map((el, i) => {
				let { label, value, sublabel } = el;
				return (
					<ContainerRadioRow key={i}>
						<input
							type={'radio'}
							value={value}
							name={ref}
							id={value}
							onChange={onChangeRadio}
							className="-comp-form-input"
							checked={checked == value}
						/>
						<Label style={{ marginRight: 5 }} for={value}>
							{label}
						</Label>
						<LabelLight for={value}>{sublabel}</LabelLight>
					</ContainerRadioRow>
				);
			})}
			<small class={`form-text pl-1}`} />
		</div>
	);
}

export function RadioeDePart({ item, onChangeRadio }) {
	let { ref, title, data } = item;
	return (
		<ContainerForm>
			<ContainerRow key={ref}>
				<Label textAlign={'left'} style={{ minWidth: '150px' }}>
					{title}
				</Label>
				<div className="styleScroll scrollDepart">
					{data.map((el, i) => {
						let { label, value, type } = el;
						return (
							<ContanierRadio key={i}>
								<input
									type={'radio'}
									value={[ value, type ]}
									id={value + 'dep'}
									name={ref}
									onChange={onChangeRadio}
									className="-comp-form-input"
								/>
								<LabelLight htmlFor={value + 'dep'}>{label}</LabelLight>
							</ContanierRadio>
						);
					})}
				</div>
				<small class={`form-text pl-1}`} />
			</ContainerRow>
		</ContainerForm>
	);
}
export function InputRow({ item, onChangeText, active, value, errors }) {
	let { data, xs, sm, md, ref, length } = item;
	return (
		<ContainerForm>
			<Row>
				{data.map((el, i) => {
					return (
						<Col key={i} sm={sm} md={md} xs={xs}>
							<InputForm
								active={active}
								item={el}
								name={ref}
								onChangeText={(e, reg) => onChangeText(e, reg)}
								maxLength={length}
								value={value}
								errors={errors}
							/>
						</Col>
					);
				})}
			</Row>
			<small class={`form-text pl-1}`} />
		</ContainerForm>
	);
}

export function Textarea({ item, onChangeText, errors }) {
	let { ref, title, placeholder, mark, reg } = item;
	return (
		<LabelInput key={ref}>
			<Label>{title}</Label>
			<InputTextarea
				placeholder={placeholder}
				name={ref}
				onChange={(e) => onChangeText(e, reg)}
				className="form-control  styleScroll"
				bc={errors && errors[ref] ? '#ff3300' : ''}
			/>
			<small class={`form-text pl-1 ${errors && errors[ref] ? 'error_text' : ''}`}>{mark}</small>
		</LabelInput>
	);
}

export function InputID({ item, onChangeText, value, errors }) {
	let { ref, title, mark, reg } = item;
	const IDInput = (props) => (
		<InputMask maskChar={null} mask={'9-9999-99999-99-9'} {...props} placeholder="หมายเลขประจำตัวประชาชน" />
	);
	return (
		<div key={ref} className="-comp-form-cardno">
			<Label>{title}</Label>
			<IDInput
				onChange={(e) => onChangeText(e, reg)}
				name={ref}
				inputMode="numeric"
				type="text"
				className="form-control "
				value={value}
				style={{ borderColor: errors && errors[ref] ? '#ff3300' : '' }}
			/>
			<small class={`form-text pl-1 ${errors && errors[ref] ? 'error_text' : ''}`}>{mark}</small>
		</div>
	);
}
export function InputIDregis({ item, onChangeText, value, errors }) {
	let { ref, title, mask, placeholder, mark, reg } = item;
	return (
		<div key={ref} className="-comp-form-cardno flex-wrap">
			<Label>{title}</Label>

			<InputMask
				maskChar={null}
				mask={mask}
				onChange={(e) => onChangeText(e, reg)}
				placeholder={placeholder}
				name={ref}
				inputMode="numeric"
				type="text"
				className="form-control "
				value={value}
				style={{ width: '100%', borderColor: errors && errors[ref] ? '#ff3300' : '' }}
			/>
			<small class={`form-text pl-1 ${errors && errors[ref] ? 'error_text' : ''}`}>{mark}</small>
		</div>
	);
}

export function SelectSex({ item, onChangeSelect, active, value, errors }) {
	let { ref, title, mark, reg } = item;
	return (
		<React.Fragment>
			<div key={ref} className="LabelInput-panel">
				<Label>{title}</Label>
				<select
					name={ref}
					onChange={(e) => onChangeSelect(e, reg)}
					className="selectTitleName"
					disabled={!active}
					value={value ? value[ref] : null}
					style={{ borderColor: errors && errors[ref] ? '#ff3300' : '' }}
				>
					<option value="0" disabled selected>
						เลือก
					</option>
					<option value="นางสาว">นางสาว</option>
					<option value="นาง">นาง</option>
					<option value="นาย">นาย</option>
				</select>
			</div>
			<small class={`form-text pl-1 ${errors && errors[ref] ? 'error_text' : ''}`}>{mark}</small>
		</React.Fragment>
	);
}
export function SelectName({ item, onChangeText, active, value, errors }) {
	let { data, xs, sm, md, mdT, xsT } = item;
	return (
		<ContainerForm>
			<Row>
				{data.map((el, i) => {
					switch (el.type) {
						case 'input':
							return (
								<Col key={i} sm={sm} md={md} xs={xs}>
									<InputForm
										active={active}
										item={el}
										onChangeText={(e, reg) => onChangeText(e, reg)}
										value={value}
										errors={errors}
									/>
								</Col>
							);
						case 'selected':
							return (
								<Col key={i} sm={sm} md={mdT} xs={xsT}>
									<SelectSex
										active={active}
										item={el}
										onChangeSelect={(e, reg) => onChangeText(e, reg)}
										value={value}
										errors={errors}
									/>
								</Col>
							);
						default:
							return <div />;
					}
				})}
			</Row>
		</ContainerForm>
	);
}
// ------------------------ register ------------------------
export function InputRowProv({ item, onChangeText, errors }) {
	let { data, xs, sm, md, ref } = item;
	return (
		<ContainerForm>
			<Row>
				{data.map((el, i) => {
					return (
						<Col sm={sm} md={md} xs={xs} key={i}>
							<InputForm
								item={el}
								name={ref}
								onChange={(e, reg) => onChangeText(e, reg)}
								errors={errors}
							/>
						</Col>
					);
				})}
			</Row>
		</ContainerForm>
	);
}

//----------------------- test by khawkriab ------------------
export function SelectSubtype({ item, onChangeSelect, head, value, disabled, className, errors }) {
	let { ref, title, mark, reg } = item;
	if (head >= 1) {
		return (
			<div className={`form-group select-arrow ${className}`}>
				<label>{title}</label>
				<select
					disabled={disabled}
					value={value}
					onChange={(e) => onChangeSelect(e, reg)}
					name={ref}
					className="form-control"
				>
					<option value="">เลือก</option>
					{item[`${head}`].data.map((el, i) => {
						let { option, value } = el;
						return (
							<React.Fragment>
								<option value={value}>{value}</option>
							</React.Fragment>
						);
					})}
				</select>
				<small class={`form-text pl-1 ${errors && errors[ref] ? 'error_text' : ''}`}>{mark}</small>
			</div>
		);
	} else {
		return (
			<div className={`form-group select-arrow ${className}`}>
				<label>{title}</label>
				<select
					disabled={disabled}
					value={value}
					onChange={(e) => onChangeSelect(e, reg)}
					name={ref}
					className="form-control"
				>
					<option value="เลือก">เลือก</option>
				</select>
				<small class={`form-text pl-1 ${errors && errors[ref] ? 'error_text' : ''}`}>{mark}</small>
			</div>
		);
	}
}

export function SelectType({ item, onChangeSelect, head, value, className, errors }) {
	let { ref, title, mark, reg } = item;
	return (
		<div className={`form-group select-arrow ${className}`}>
			<label>{title}</label>
			<select value={value} onChange={(e) => onChangeSelect(e, reg)} name={value} className="form-control">
				<option value="">เลือก</option>
				{item.data.map((el, i) => {
					let { label } = el;
					return (
						<React.Fragment>
							<option value={label}>{label}</option>
						</React.Fragment>
					);
				})}
			</select>
			<i>{/* icon */}</i>
			<small class={`form-text pl-1 ${errors && errors[ref] ? 'error_text' : ''}`}>{mark}</small>
		</div>
	);
}
export function SelectTypeSerach({ item, onChangeSelect, head, value, className, errors }) {
	let { ref, title, mark, reg } = item;
	return (
		<div className={`form-group select-arrow ${className}`}>
			<label>{title}</label>
			<select value={value} onChange={(e) => onChangeSelect(e, reg)} name={value} className="form-control">
				<option value="">เลือก</option>
				{item.data.map((el, i) => {
					let { label, value } = el;
					return (
						<React.Fragment>
							<option value={value}>{label}</option>
						</React.Fragment>
					);
				})}
			</select>
			<i>{/* icon */}</i>
			<small class={`form-text pl-1 ${errors && errors[ref] ? 'error_text' : ''}`}>{mark}</small>
		</div>
	);
}
export function InputFormGroup({
	title,
	name,
	placeholder,
	onChange,
	disabled,
	value,
	r,
	onKeyDown,
	className,
	type,
	mark,
	reg,
	errors
}) {
	return (
		<div className={`form-group  ${className}`} style={{ textAlign: 'left' }}>
			<label>{title}</label>
			<input
				type={type ? type : 'text'}
				className="form-control"
				disabled={disabled}
				id={name}
				name={name}
				placeholder={placeholder}
				onChange={(e) => onChange(e, reg)}
				onKeyDown={onKeyDown}
				value={value}
				style={{ borderRadius: r,  borderColor: errors && errors[name] ? '#ff3300' : ''  }}
			/>
			<small class={`form-text pl-1 ${errors && errors[name] ? 'error_text' : ''}`}>{mark}</small>
		</div>
	);
}
export function InputFormGroupArea({ title, name, placeholder, onChange, disabled, value, mark, reg, errors }) {
	return (
		<div className="form-group" style={{ textAlign: 'left' }}>
			<label>{title}</label>
			<textarea
				className="form-control"
				disabled={disabled}
				id={name}
				name={name}
				placeholder={placeholder}
				onChange={(e) => onChange(e, reg)}
				value={value}
			/>
			<small class={`form-text pl-1 ${errors && errors[name] ? 'error_text' : ''}`}>{mark}</small>
		</div>
	);
}
export function SelectFormGroup({
	title,
	name,
	onChange,
	disabled,
	currentItem,
	allItem,
	className,
	mark,
	reg,
	errors
}) {
	// item show current value
	return (
		<div className={`form-group select-arrow ${className}`}>
			{title && <label>{title}</label>}
			<select disabled={disabled} onChange={(e) => onChange(e, reg)} name={name} className="form-control">
				{/* warning value = item in option*/}
				<option value="">{currentItem ? currentItem : 'กรุณาเลือก'}</option>
				{allItem &&
					allItem.map((el, i) => {
						let { label, value } = el;
						return <option value={value ? value : label}>{label}</option>;
					})}
			</select>
			<small class={`form-text pl-1 ${errors && errors[name] ? 'error_text' : ''}`}>{mark}</small>
		</div>
	);
}
export function SelectCagetory({
	title,
	name,
	onChange,
	disabled,
	currentItem,
	allItem,
	className,
	mark,
	reg,
	errors
}) {
	// item show current value
	return (
		<div className={`form-group select-arrow ${className}`}>
			{title && <label>{title}</label>}
			<select disabled={disabled} onChange={(e) => onChange(e, reg)} name={name} className="form-control">
				{/* warning value = item in option*/}
				<option value="">{currentItem ? currentItem : 'กรุณาเลือก'}</option>
				{allItem &&
					allItem.map((el, i) => {
						let { label, value } = el;
						return <option value={value}>{label}</option>;
					})}
			</select>
			<small class={`form-text pl-1 ${errors && errors[name] ? 'error_text' : ''}`}>{mark}</small>
		</div>
	);
}
export function RadioFormGroup({ title, name, allItem, currentItem, onChange, xs, md, lg, xl, other, mark, reg, errors }) {
	return (
		<ContainerForm>
			<ContainerRow key={name}>
				<Label textAlign={'left'} style={{ minWidth: '150px' }}>
					{title}
				</Label>
				<Row>
					{allItem.map((el, i) => {
						let { label, value } = el;
						return (
							<Col key={i} xs={xs} md={md} lg={lg} xl={xl}>
								<ContanierRadio key={i}>
									<input
										type={'radio'}
										value={value}
										name={name}
										id={value}
										onChange={(e) => onChange(e, reg)}
										className="-comp-form-input"
										checked={currentItem == value}
									/>
									<LabelLight for={value}>{label}</LabelLight>
									{label == 'ช่องทางอื่น ๆ' && (
										<input
											name="other"
											onChange={(e) => onChange(e, reg)}
											value={other}
											type="text"
											placeholder="ระบุ"
										/>
									)}
								</ContanierRadio>
							</Col>
						);
					})}
				</Row>
				<small class={`form-text pl-1 ${errors && errors[name] ? 'error_text' : ''}`}>{mark}</small>
			</ContainerRow>
		</ContainerForm>
	);
}
