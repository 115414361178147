export default {
    Asset1: require('../Asset/icons/Asset1.png'),
    Asset2: require('../Asset/icons/Asset2.png'),
    Asset3: require('../Asset/icons/Asset3.png'),
    Asset4: require('../Asset/icons/Asset4.png'),
    Asset5: require('../Asset/icons/Asset5.png'),
    Asset6: require('../Asset/icons/Asset6.png'),
    Asset7: require('../Asset/icons/Asset7.png'),
    Asset8: require('../Asset/icons/Asset8.png'),
    Asset9: require('../Asset/icons/Asset9.png'),
    Asset10: require('../Asset/icons/Asset10.png'),
    Asset11: require('../Asset/icons/Asset11.png'),
    Asset12: require('../Asset/icons/Asset12.png'),
    Asset13: require('../Asset/icons/Asset13.png'),
    Asset14: require('../Asset/icons/Asset14.png'),
    Asset15: require('../Asset/icons/Asset15.png'),
    Asset16: require('../Asset/icons/Asset16.png'),
    Asset17: require('../Asset/icons/Asset17.png'),
    Asset18: require('../Asset/icons/Asset18.png'),
    Asset19: require('../Asset/icons/Asset19.png'),
    Asset20: require('../Asset/icons/Asset20.png'),
    Asset21: require('../Asset/icons/Asset21.png'),
    Asset22: require('../Asset/icons/Asset22.png'),
    Asset23: require('../Asset/icons/Asset23.png'),
    Asset24: require('../Asset/icons/Asset24.png'),
    Asset25: require('../Asset/icons/Asset25.png'),
    Asset26: require('../Asset/icons/Asset26.png'),
    Asset27: require('../Asset/icons/Asset27.png'),
    Asset28: require('../Asset/icons/Asset28.png'),
    Asset29: require('../Asset/icons/Asset29.png'),
    Asset30: require('../Asset/icons/Asset30.png'),
    Asset31: require('../Asset/icons/Asset31.png'),
    Asset32: require('../Asset/icons/Asset32.png'),
    Asset33: require('../Asset/icons/Asset33.png'),
    Asset34: require('../Asset/icons/Asset34.png'),
    Asset35: require('../Asset/icons/Asset35.png'),
    Asset36: require('../Asset/icons/Asset36.png'),
    Asset37: require('../Asset/icons/Asset37.png'),
    Asset38: require('../Asset/icons/Asset38.png'),
    Asset39: require('../Asset/icons/Asset39.png'),
    Asset40: require('../Asset/icons/Asset40.png'),
    Asset41: require('../Asset/icons/Asset41.png'),
    Asset42: require('../Asset/icons/Asset42.png'),
    Asset43: require('../Asset/icons/Asset43.png'),
    Asset44: require('../Asset/icons/Asset44.png'),
    Asset45: require('../Asset/icons/Asset45.png'),
    Asset46: require('../Asset/icons/Asset46.png'),
    Asset47: require('../Asset/icons/Asset47.png'),
    Asset48: require('../Asset/icons/Asset48.png'),
    Asset49: require('../Asset/icons/Asset49.png'),
    Asset50: require('../Asset/icons/Asset50.png'),

    bglogin: require('../Asset/icons/bglogin.png'),
    bglogin2: require('../Asset/icons/bglogin2.png'),

    empty: require('../Asset/icons/empty.png'),

    drawer: require('../Asset/icons/drawer pic.png'),
    logo: require('../Asset/icons/logo app.png'),
    Noti: require('../Asset/icons/notifications-button.png'),
    username: require('../Asset/icons/username.png'),
    password: require('../Asset/icons/password.png'),

    appleapp : require('../Asset/icons/apple-store.png'),
    googleplayapp : require('../Asset/icons/googleplay-app.png')
}