import React, { Component } from 'react'
import { ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '../../Asset/styleAdmin';
import { Label } from '../../Asset/styled';
import { InputFormGroup, InputIDregis } from '../Form/Input'
import { Img } from '../Image/Img'
import { get, post, path } from '../../api';
import Modal from 'react-responsive-modal'
import swal from 'sweetalert'
import { Row, Col } from 'reactstrap';

export default class EditProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModalFP: false,
            user_info_id: null,
            username: '',
            email: '',
            fname: '',
            lname: '',
            phone: '',
            position: "",
            newpassword: '',
            oldpassword: '',
            newpassword2: '',
            error: {},
            prefix: '',
            id_card: '',
            rear_id_card: '',
            house_number: '',
            house_name: '',
            moo: '',
            alleyway: '-',
            road: '',
            password: '',
            old_password: '',
            allDistrict: [],
            allProvince: [],
            allTombon: [],
            // จังหวัด
            province: '',
            district: '',
            sub_district: '',
            zipcode: '',
        }
    }
    componentDidMount() {
        this.fetchUser()
    }
    // ------------------------------------------------------------------------------------------------------------------
    onOpenModalFP = () => {
        this.props.onCloseModal()
        this.setState({ openModalFP: !this.state.openModalFP });
    };
    // ------------------------------------------------------------------------------------------------------------------
    async fetchUser() {
        let userlocal = JSON.parse(localStorage.getItem('user'))
        try {
            const user = await post('/central/central_me/', { user_id: userlocal.user_id })
            const prof = await get('/auth/profile')
            this.setState({
                user_info_id: user.user_info_id,
                username: user.username,
                email: user.email,
                fname: user.fname,
                lname: user.lname,
                position: user.position,
                phone: user.phone,
                pic: prof.path,
                alleyway: prof.alleyway,
                district: prof.district,
                house_number: prof.house_number,
                house_name: prof.house_name,
                id_card: prof.id_card,
                rear_id_card: prof.rear_id_card,
                moo: prof.moo,
                prefix: prof.prefix,
                province: prof.province,
                road: prof.road,
                sub_district: prof.sub_district,
                zipcode: prof.zipcode,
            })
            console.log('prof', this.state.pic);
            // console.log('user', user);
        } catch (error) {
            console.log('error', error)
        }

    }
    // -----------------------------------------------------------------------------------------------------------------------
    onUpdate = async () => {
        const { user_info_id, fname, lname, phone, username, email, position } = this.state
        const item = await post('/central/update_user', { user_info_id, fname, lname, phone, username, email, position })
        if (item) {
            swal({
                title: "เรียบร้อย",
                text: 'บันทึการแก้ไขแล้ว',
                icon: "success",
            })
                .then(() => {
                    window.location.reload()
                    // this.componentDidMount()
                });
        }
    }
    // -----------------------------------------------------------------------------------------------------------------------
    onUpdateUser = async () => {
        const { user_info_id, prefix, fname, lname, phone, username, email, id_card, rear_id_card, house_number, house_name, moo, alleyway, road, sub_district, district, province, zipcode } = this.state
        if (!fname) {
            swal("คำเตือน", "กรุณากรอกชื่อจริง", "warning")
        } else if (!lname) {
            swal("คำเตือน", "กรุณากรอกนามสกุล", "warning")
            // } else if (id_card) {
            //     swal("คำเตือน", "กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง", "warning")
            // } else if (rear_id_card) {
            //     swal("คำเตือน", "กรุณากรอกเลขหลังบัตรประชาชนให้ถูกต้อง", "warning")
        } else {
            let id_cards = id_card ? id_card.replace(/(-)/g, '') : ''
            let rear_id_cards = rear_id_card ? rear_id_card.replace(/(-)/g, '') : 'F'
            const item = await post('/auth/update_profile', { user_info_id, prefix, fname, lname, phone, username, email, id_card: id_cards, rear_id_card: rear_id_cards, house_number, house_name, moo, alleyway, road, sub_district, district, province, zipcode })
            if (item) {
                swal({
                    title: "เรียบร้อย",
                    text: 'บันทึการแก้ไขแล้ว',
                    icon: "success",
                })
                    .then(() => {
                        window.location.reload()
                        // this.componentDidMount()
                    });
            }
        }
    }
    // -----------------------------------------------------------------------------------------------------------------------
    onChangePassword = async () => {
        let { newpassword, oldpassword, newpassword2, error, user_info_id, username } = this.state
        if (oldpassword === '') {
            swal("คำเตือน", "กรุณากรอกข้อมูลให้ครบ", "warning")
            error['oldpassword'] = true
            this.setState({ error })
        } else if (newpassword === '') {
            error['newpassword'] = true
            this.setState({ error })
            swal("คำเตือน", "กรุณากรอกข้อมูลให้ครบ", "warning")
        } else if (newpassword !== newpassword2) {
            error['newpassword2'] = true
            this.setState({ error })
            swal("คำเตือน", "รหัสผ่านไม่ตรงกัน", "warning")
        } else {
            try {
                const change = await post('/central/update_user', { user_info_id, username, password: oldpassword, newpassword })
                if (change) {
                    swal("สำเร็จ", "เปลี่ยนรหัสผ่านสำเร็จ", "success")
                }
                this.setState({ openModalFP: !this.state.openModalFP });
            } catch (error) {
                swal("คำเตือน", error, "warning")
                console.log('error', error);
            }
        }
    }
    // -----------------------------------------------------------------------------------------------------------------------
    onChangePasswordUser = async () => {
        let { newpassword, oldpassword, newpassword2, error, username } = this.state
        if (oldpassword === '') {
            swal("คำเตือน", "กรุณากรอกข้อมูลให้ครบ", "warning")
            error['oldpassword'] = true
            this.setState({ error })
        } else if (newpassword === '') {
            error['newpassword'] = true
            this.setState({ error })
            swal("คำเตือน", "กรุณากรอกข้อมูลให้ครบ", "warning")
        } else if (newpassword !== newpassword2) {
            error['newpassword2'] = true
            this.setState({ error })
            swal("คำเตือน", "รหัสผ่านไม่ตรงกัน", "warning")
        } else {
            try {
                const change = await post('/auth/change_password', { username, password: oldpassword, newpassword })
                if (change) {
                    swal("สำเร็จ", "เปลี่ยนรหัสผ่านสำเร็จ", "success")
                }
                this.setState({ openModalFP: !this.state.openModalFP });
            } catch (error) {
                swal("คำเตือน", error, "warning")
                console.log('error', error);
            }
        }
    }
    // -----------------------------------------------------------------------------------------------------------------------
    _onChangeText = (e, reg) => {
        let { error } = this.state
        this.setState({ [e.target.name]: e.target.value })

        if (!e.target.value.match(reg)) {
            error[e.target.name] = true
            this.setState({ error });
        } else {
            error[e.target.name] = false
            this.setState({ error })
        }
    }
    // -----------------------------------------------------------------------------------------------------------------------
    render() {
        let { onOpenModal, onCloseModal, onOpenModalU } = this.props
        return (
            <div>
                <Modal
                    open={onOpenModal}
                    onClose={onCloseModal}
                    little
                >
                    <ModalContent>
                        <ModalHeader>แก้ไขโปรไฟล์</ModalHeader>
                        <ModalBody>
                            <div className="d-flex justify-content-between">
                                <div className="text-center">
                                    <Img src={path + this.state.pic}
                                        w="140px"
                                        h="140px"
                                        border="1px solid #ddd"
                                        px="0px"
                                        py="0px"
                                        raduis={'50%'}
                                    />
                                    <br />
                                    <a href onClick={this.onOpenModalFP}>เปลี่ยนรหัสผ่าน</a>
                                </div>
                                <div style={{ width: '320px' }}>
                                    <InputFormGroup title="ชื่อผู้ใช้" name="username" value={this.state.username} onChange={this._onChangeText} disabled />
                                    <InputFormGroup title="E-mail" name="email" value={this.state.email} onChange={this._onChangeText} disabled />
                                    <InputFormGroup title="ชื่อจริง" name="fname" value={this.state.fname} onChange={this._onChangeText} />
                                    <InputFormGroup title="นามสกุล" name="lname" value={this.state.lname} onChange={this._onChangeText} />
                                    <InputFormGroup title="ตำแหน่ง" name="position" value={this.state.position} onChange={this._onChangeText} />
                                    <InputFormGroup title="หมายเลขโทรศัพท์" name="phone" value={this.state.phone} onChange={this._onChangeText} />

                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.onUpdate}>บันทึก</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal >
                <Modal
                    open={this.state.openModalFP}
                    onClose={this.onOpenModalFP}
                    little
                >
                    <ModalContent>
                        <ModalHeader>เปลี่ยนรหัสผ่าน</ModalHeader>
                        <ModalBody>
                            <InputFormGroup
                                title="รหัสผ่านเดิม"
                                name="oldpassword"
                                value={this.state.oldpassword}
                                onChange={this._onChangeText}
                                errors={this.state.error}
                                type={'password'}
                            />
                            <InputFormGroup
                                title="รหัสผ่านใหม่"
                                name="newpassword"
                                value={this.state.newpassword}
                                onChange={this._onChangeText}
                                errors={this.state.error}
                                mark='กรุณากรอกรหัสผ่านอย่างน้อย 8 - 16 ตัวอักษร เป็นอักษรอังกฤษหรือตัวเลขเท่านั้น'
                                reg={/^([a-zA-Z0-9]{8,16})/}
                                type={'password'} />
                            <InputFormGroup
                                title="ยืนยันรหัสผ่าน"
                                name="newpassword2"
                                value={this.state.newpassword2}
                                onChange={this._onChangeText}
                                errors={this.state.error}
                                reg={/^([a-zA-Z0-9]{8,16})/}
                                type={'password'} />
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.onChangePassword}>บันทึก</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal >
                {/* -----user ----------------------------------------------------------------- */}
                <Modal
                    open={onOpenModalU}
                    onClose={onCloseModal}
                    little
                >
                    <ModalHeader>แก้ไขโปรไฟล์</ModalHeader>
                    <ModalBody>
                        <div className="d-flex">
                            <div className="text-center">
                                <Img src={path + this.state.pic}
                                    w="100px"
                                    h="100px"
                                    border="1px solid #ddd"
                                    px="0px"
                                    py="0px"
                                    raduis={'50%'}
                                />
                                <br />
                                <a href onClick={this.onOpenModalFP}>เปลี่ยนรหัสผ่าน</a>
                            </div>
                            <div className="pl-5">
                                <Row>
                                    <Col sm={4}>
                                        <InputFormGroup title="ชื่อผู้ใช้" name="username" value={this.state.username} onChange={this._onChangeText} disabled />
                                    </Col>
                                    <Col sm={2}>
                                        <div className="LabelInput-panel">
                                            <Label>คำนำหน้า</Label>
                                            <select
                                                name="prefix"
                                                onChange={this._onChangeText}
                                                className="selectTitleName"
                                                value={this.state.prefix}
                                            >
                                                <option > {this.state.prefix ? this.state.prefix : 'เลือก'}</option>
                                                <option value="นางสาว">นางสาว</option>
                                                <option value="นาง">นาง</option>
                                                <option value="นาย">นาย</option>
                                            </select>
                                        </div>
                                        <small class={`form-text pl-1 `}></small>
                                        {/* <SelectSex item={{ ref: 'prefix', title: 'คำนำหน้า' }} active={true} onChangeSelect={this._onChangeText} value={this.state.prefix} /> */}
                                        {/* <InputFormGroup title="คำนำหน้า" name="prefix" value={this.state.prefix} onChange={this._onChangeText} /> */}
                                    </Col>
                                    <Col sm={3}>
                                        <InputFormGroup title="ชื่อจริง" name="fname" value={this.state.fname} onChange={this._onChangeText} />
                                    </Col>
                                    <Col sm={3}>
                                        <InputFormGroup title="นามสกุล" name="lname" value={this.state.lname} onChange={this._onChangeText} />
                                    </Col>
                                    {/* ------------------------------------ */}
                                    <Col sm={4}>
                                        <InputIDregis item={{ ref: 'id_card', title: 'เลขบัตรประจำตัวประชาชน', mask: '9-9999-99999-99-9' }} onChangeText={this._onChangeText} value={this.state.id_card} />
                                    </Col>
                                    <Col sm={4}>
                                        <InputIDregis item={{ ref: 'rear_id_card', title: 'เลขหลังบัตรประจำตัวประชาชน', mask: 'aa9-9999999-99' }} onChangeText={this._onChangeText} value={this.state.rear_id_card} />
                                    </Col>
                                    <Col sm={4}>
                                        <InputFormGroup title="เบอร์โทรศัพท์" name="phone" value={this.state.phone} onChange={this._onChangeText} />
                                    </Col>
                                    {/* ------------------------------------ */}
                                    <Col sm={3}>
                                        <InputFormGroup title="หมู่บ้าน" name="house_name" value={this.state.house_name} onChange={this._onChangeText} />
                                    </Col>
                                    <Col sm={3}>
                                        <InputFormGroup title="บ้านเลขที่" name="house_number" value={this.state.house_number} onChange={this._onChangeText} />
                                    </Col>
                                    <Col sm={3}>
                                        <InputFormGroup title="หมู่ที่" name="moo" value={this.state.moo} onChange={this._onChangeText} />
                                    </Col>
                                    <Col sm={3}>
                                        <InputFormGroup title="ตรอกซอย" name="alleyway" value={this.state.alleyway} onChange={this._onChangeText} />
                                    </Col>
                                    <Col sm={3}>
                                        <InputFormGroup title="ถนน" name="road" value={this.state.road} onChange={this._onChangeText} />
                                    </Col>
                                    {/* ------------------------------------ */}
                                    <Col sm={3}>
                                        <InputFormGroup title="จังหวัด" name="province" value={this.state.province} onChange={this._onChangeText} />
                                    </Col>
                                    <Col sm={3}>
                                        <InputFormGroup title="อำเภอ" name="district" value={this.state.district} onChange={this._onChangeText} />
                                    </Col>
                                    <Col sm={3}>
                                        <InputFormGroup title="ตำบล" name="sub_district" value={this.state.sub_district} onChange={this._onChangeText} />
                                    </Col>
                                    <Col sm={3}>
                                        <InputFormGroup title="รหัสไปรษณีย์" name="zipcode" value={this.state.zipcode} onChange={this._onChangeText} />
                                    </Col>
                                    {/* ------------------------------------ */}
                                </Row>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.onUpdateUser}>บันทึก</Button>
                    </ModalFooter>
                </Modal >
                <Modal
                    open={this.state.openModalFP}
                    onClose={this.onOpenModalFP}
                    little
                >
                    <ModalContent>
                        <ModalHeader>เปลี่ยนรหัสผ่าน</ModalHeader>
                        <ModalBody>
                            <InputFormGroup
                                title="รหัสผ่านเดิม"
                                name="oldpassword"
                                value={this.state.oldpassword}
                                onChange={this._onChangeText}
                                errors={this.state.error}
                                type={'password'}
                            />
                            <InputFormGroup
                                title="รหัสผ่านใหม่"
                                name="newpassword"
                                value={this.state.newpassword}
                                onChange={this._onChangeText}
                                errors={this.state.error}
                                mark='กรุณากรอกรหัสผ่านอย่างน้อย 8 - 16 ตัวอักษร เป็นอักษรอังกฤษหรือตัวเลขเท่านั้น'
                                reg={/^([a-zA-Z0-9]{8,16})/}
                                type={'password'} />
                            <InputFormGroup
                                title="ยืนยันรหัสผ่าน"
                                name="newpassword2"
                                value={this.state.newpassword2}
                                onChange={this._onChangeText}
                                errors={this.state.error}
                                reg={/^([a-zA-Z0-9]{8,16})/}
                                type={'password'} />
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.onChangePasswordUser}>บันทึก</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal >
            </div>
        )
    }
}
